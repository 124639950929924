import React from "react"

const ContactInfo = ({ image, name, position, phone, email, location_title, location_content }) => (
	<section className="contact-info">
        <div className="container">
            <div className="row">
                <div className="col-12 contact-info__holder">
                    <img src={ image } alt="" className="contact-info__image"/>
                    <div className="contact-info__column-holder">
                        <div className="contact-info__column">
                            <h3>
                                { name }
                            </h3>
                            <p className="contact-info__subtitle mb-20">
                                { position }
                            </p>
                            <div className="contact-info__info-with-icon">
                                <img src={require('../assets/img/phoneIconMain.png')} alt="Telefon" className="info-with-icon__icon" />
                                <a href={`tel:${ phone }`} className="info-with-icon__link">
                                    { phone }
                                </a>
                            </div>
                            <div className="contact-info__info-with-icon">
                                <img src={require('../assets/img/mailIconMain.png')} alt="Mail" className="info-with-icon__icon" />
                                <a href={`mailto:${ email }`}  className="info-with-icon__link">
                                    { email }
                                </a>
                            </div>
                        </div>
                        <div className="contact-info__column">
                            <h3 className="mb-50">
                                { location_title }
                            </h3>
                            <div className="contact-info__info-with-icon">
                                <img src={require('../assets/img/BuildingIconMain.png')} alt="Budynek" className="info-with-icon__icon" />
                                <p dangerouslySetInnerHTML={{ __html: location_content }}>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default ContactInfo

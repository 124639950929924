import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SmallBanner from "../components/smallBanner"
import ContactInfo from "../components/contactInfo"
import ContactForm from "../components/contactForm"

const Contact = () => {
	const AcfQuery = useStaticQuery(
		graphql`
		  query ContactQuery {
			allWordpressPage(filter: {title: {eq: "Kontakt"}}) {
			  edges {
				node {
				  acf {
					banner_title_first_part
					banner_title_second_part
					banner_image {
						localFile{
							url
						}
					}
					contact_image {
						localFile{
							url
						}
					}
					contact_name
					contact_position
					contact_phone
					contact_email
					contact_location_title
					contact_location_content
				  }
				}
			  }
			}
		  }
		`
	)

	const data = AcfQuery.allWordpressPage.edges[0].node.acf;

	return (
		<Layout>
			<SEO title="Kontakt" />
			<SmallBanner 
				image={ data.banner_image.localFile.url } 
				// image={ require('../assets/img/ContactBanner.jpg') }
				titleFirstPart={ data.banner_title_first_part } 
				titleSecondPart={ data.banner_title_second_part } 
			/>
			<ContactInfo 
				image={ data.contact_image.localFile.url }
				// image={ require('../assets/img/person.jpg') }
				name={ data.contact_name }
				position={ data.contact_position }
				phone={ data.contact_phone }
				email={ data.contact_email }
				location_title={ data.contact_location_title }
				location_content={ data.contact_location_content }
			/>
			<ContactForm />
		</Layout>
	)
}

export default Contact

import React from "react"
import axios from "axios";

class ContactForm extends React.Component {

    state = {
        firstName: "",
        subject: "",
        email: "",
        phone: "",
        message: "",
        checked: false,
        sent: false,
        buttonText: "Wyślij wiadomość",
        validationMessage: "",
        isCheckboxTextExpand: false,
        checkboxText: 'Administratorem danych osobowych jest Żółkiewskiego 7 Skavia Development spółka z ograniczoną odpowiedzialnością spółka komandytowa z siedzibą w Warszawie...',
      };
    
      resetForm = () => {
        this.setState({
          ...this.state,
          firstName: "",
          subject: "",
          email: "",
          phone: "",
          message: "",
          checked: false,
          buttonText: "Wiadomość wysłana",
          validationMessage: '',
        });
      };

      checkboxTextExpand = e => {
          e.preventDefault();
        if (this.state.isCheckboxTextExpand) {
            this.setState({
                ...this.state,
                isCheckboxTextExpand: false,
                checkboxText: 'Administratorem danych osobowych jest Żółkiewskiego 7 Skavia Development spółka z ograniczoną odpowiedzialnością spółka komandytowa z siedzibą w Warszawie...',
            });
        } else {
            this.setState({
                ...this.state,
                isCheckboxTextExpand: true,
                checkboxText: 'Administratorem danych osobowych jest Żółkiewskiego 7 Skavia Development spółka z ograniczoną odpowiedzialnością spółka komandytowa z siedzibą w Warszawie adres: ul. Marszałkowska 58 lok. 15, 00-545 Warszawa, telefon: 22 230 22 65 e-mail: <a href="mailto:kontakt@zolkiewskiego7.pl">kontakt@zolkiewskiego7.pl</a>. <br /><br />Celem przetwarzania danych osobowych jest otrzymanie przez osobę fizyczną, na jej żądanie, informacji handlowych, w tym marketingowych, dotyczących produktów i usług oferowanych przez administratora danych osobowych. <br /><br />Przeczytaj więcej w <a target="_blank" href="http://wp.zolkiewskiego7.pl/wp-content/uploads/2020/11/SKAV_RODO_karta-inf-dla-klientów_kontakt-jednorazowy_20200915.pdf">REGULAMINIE</a>',
            });
        }
      }
    
      formSubmit = (e) => {
        e.preventDefault();
    
        this.setState({
          buttonText: "...wysyłanie",
        });
    
        let formData = new FormData();
    
        formData.set("firstName", this.state.firstName);
        formData.set("subject", this.state.subject);
        formData.set("email", this.state.email);
        formData.set("phone", this.state.phone);
        formData.set("message", this.state.message);
    
        axios({
          method: "post",
          url:
            "https://wp.zolkiewskiego7.pl/wp-json/contact-form-7/v1/contact-forms/22/feedback/",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((res) => {
            this.setState({ sent: true }, this.resetForm());
          })
          .catch(() => {
            console.log("Błąd wysyłania wiadomości...");
          });
      };
      render() {
    return (
        <section className="contact-form">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="title-underline mb-40">
                            Formularz kontaktowy
                        </h2>
                    </div>
                </div>
            </div>
            <div className="contact-form__form-holder">
                <div className="container">
                    <form onSubmit={(e) => this.formSubmit(e)} className="form">
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <div className="form__input-wrapper">
                                    <label for="firstName" htmlFor="firstName">Imię:</label>
                                    <input
                                        aria-label="firstName"
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Podaj imię..."
                                        autoComplete="off"
                                        value={this.state.firstName}
                                        onChange={(e) => this.setState({ firstName: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form__input-wrapper">
                                    <label htmlFor="email">Adres email:</label>
                                    <input
                                        aria-label="email"
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Podaj mail..."
                                        autoComplete="off"
                                        value={this.state.email}
                                        onChange={(e) => this.setState({ email: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form__input-wrapper">
                                    <label htmlFor="phone">Numer telefonu:</label>
                                    <input
                                        aria-label="phone"
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        placeholder="Podaj numer telefonu..."
                                        autoComplete="off"
                                        value={this.state.phone}
                                        onChange={(e) => this.setState({ phone: e.target.value })}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-8">
                                <div className="form__input-wrapper">
                                    <label htmlFor="subject">Temat wiadomości:</label>
                                    <input
                                        aria-label="subject"
                                        type="text"
                                        id="subject"
                                        name="subject"
                                        placeholder="Podaj temat..."
                                        autoComplete="off"
                                        value={this.state.subject}
                                        onChange={(e) => this.setState({ subject: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form__input-wrapper">
                                    <label htmlFor="message">Treść wiadomości:</label>
                                    <textarea
                                        aria-label="message"
                                        type="text"
                                        id="message"
                                        name="message"
                                        placeholder="Wpisz treść wiadomości..."
                                        autoComplete="off"
                                        value={this.state.message}
                                        onChange={(e) => this.setState({ message: e.target.value })}
                                        rows="9"
                                        required
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-12 form__bottom-holder">
                                <div className="check-group">
                                    <input
                                        aria-label="check-group"
                                        type="checkbox"
                                        checked={this.state.checked}
                                        onChange={(e) => this.setState({ checked: !this.state.checked })}
                                    />
                                    <div className="check-group__check"></div>
                                    <span className="checkbox-holder">
                                        <div dangerouslySetInnerHTML={{ __html: this.state.checkboxText }}></div>
                                            {/* { this.state.checkboxText}  */}
                                        <a href="#header" onClick={e => this.checkboxTextExpand(e)}>
                                            { !this.state.isCheckboxTextExpand ? 'czytaj wiecej' : ' zwiń' }
                                        </a>   
                                    </span>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn--submit"
                                    disabled={!this.state.checked}
                                >
                                    {this.state.buttonText}
                                </button>
                            </div>  
                        </div>
                    </form>
                    <div className="row">
                        <div className="col-12">
                            { this.state.validationMessage && this.state.validationMessage }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    }
}

export default ContactForm
